import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import StyledCursor from './Cursor.style';

const defaultProps = {
  triggerRef: null
};

type CursorProps = {
  triggerRef?: any;
} & typeof defaultProps;

const Cursor = ({ triggerRef }: CursorProps): JSX.Element => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hidden, setHidden] = useState(true);
  const [clicked, setClicked] = useState(false);
  let hoverComponent: any;

  const onMouseDown = () => {
    setClicked(true);
  };

  const onMouseUp = () => {
    setClicked(false);
  };

  const onMouseLeave = () => {
    setHidden(true);
  };

  const onMouseEnter = () => {
    setHidden(false);
  };

  const onMouseMove = (e: any) => {
    setHidden(false);
    setPosition({ x: e.pageX, y: e.pageY });
  };

  const addEventListeners = () => {
    hoverComponent.addEventListener('mousemove', onMouseMove);
    hoverComponent.addEventListener('mouseenter', onMouseEnter);
    hoverComponent.addEventListener('mouseleave', onMouseLeave);
    hoverComponent.addEventListener('mousedown', onMouseDown);
    hoverComponent.addEventListener('mouseup', onMouseUp);
  };

  const removeEventListeners = () => {
    hoverComponent.removeEventListener('mousemove', onMouseMove);
    hoverComponent.removeEventListener('mouseenter', onMouseEnter);
    hoverComponent.removeEventListener('mouseleave', onMouseLeave);
    hoverComponent.removeEventListener('mousedown', onMouseDown);
    hoverComponent.removeEventListener('mouseup', onMouseUp);
  };

  useEffect(() => {
    hoverComponent = document;
    if (triggerRef) {
      hoverComponent = triggerRef.current;
    }

    addEventListeners();
    return () => removeEventListeners();
  }, []);

  const cursorClasses = classNames('cursor', {
    'cursor--clicked': clicked,
    'cursor--hidden': hidden
  });

  return (
    <StyledCursor
      className={cursorClasses}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`
      }}
    >
      <svg className="cursor__icon" width="24" height="27" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.425 26.571C1.536 26.571 0 25.056 0 23.194V3.377C0 2.475.356 1.627 1.003.99A3.427 3.427 0 0 1 3.425 0c.598 0 .99.154 1.485.348l.16.075 17.133 9.829c.978.571 1.576 1.498 1.784 2.757l.013.156-.013.398c-.208 1.26-.806 2.186-1.777 2.754L5.008 26.185l-.067.027c-.523.205-.916.36-1.516.36z"
          fill="#1E5BA7"
          fillRule="nonzero"
        />
      </svg>
    </StyledCursor>
  );
};

Cursor.defaultProps = defaultProps;

export default Cursor;
