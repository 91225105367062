import styled, { css } from 'styled-components';

import { px2rem, color, mq, space, getTypo } from '../../stylesheets/tools';

type StyledHomeStageProps = {
  skipped?: boolean;
};

export const maxHeight = css`
  max-height: ${px2rem(450)};
  min-height: ${px2rem(450)};
  height: 100vh;

  ${mq('m')} {
    max-height: ${px2rem(750)};
    min-height: ${px2rem(600)};
  }

  ${mq('l')} {
    max-height: ${px2rem(1000)};
    min-height: ${px2rem(600)};
  }
`;

const StyledHomeStage = styled.div`
  margin-bottom: ${space(3)};
  position: relative;
  z-index: 0;
  scroll-snap-align: start;

  .home-stage__header {
    pointer-events: none;
    z-index: 2;
    position: absolute;
    transition: all 0.4s ease-out;
    padding-top: ${space(7)};

    ${mq('l')} {
      padding-top: ${space(15)};
    }
  }

  .home-stage__skip {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    color: #ffffff;
    border: 2px solid #ffffff;
    background-color: #000000;
    mix-blend-mode: difference;
    border-radius: 50%;
    transition: all 0.25s ease-out;
    z-index: 3;
    right: ${space(3)};
    top: ${space(3)};
    cursor: pointer;
    display: none;
    ${getTypo.CopyBold};

    ${mq('l')} {
      width: 84px;
      height: 84px;
    }

    &:hover {
      transform: scale(1.1);
      background-color: #ffffff;
      color: #000000;
    }
  }

  .home-stage__video,
  .home-stage__teaser-video {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    left: 0;
    top: 0;
    overflow: hidden;

    > video {
      object-fit: cover;
    }
  }

  .home-stage__teaser-video {
    z-index: 0;
  }

  .home-stage__picture {
    position: absolute;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: ${px2rem(1800)};
    height: auto;
  }

  .home-stage__blocker {
    cursor: none;
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    ${maxHeight}
  }

  .home-stage__video-mask {
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    z-index: 0;
    will-change: opacity;
    filter: brightness(95%);
    width: 100%;
    height: 100%;
  }

  .home-stage__headline-skipped {
    white-space: nowrap;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: #ffffff;
    transition: all 0.4s ease-out;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .home-stage__headline {
    width: 100%;
    height: 100%;

    &-path {
      width: ${px2rem(250)};

      ${mq('s')} {
        width: ${px2rem(400)};
      }
      ${mq('m')} {
        width: ${px2rem(700)};
      }
      ${mq('l')} {
        width: ${px2rem(950)};
      }

      &-container {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        position: absolute;
        z-index: 3;
        mix-blend-mode: screen;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    > rect {
      height: 100%;
      width: 100%;
      transform-origin: center center;
      fill: ${color('neutral', 'background')};
      position: relative;
      mask: url(#mask);
      mask-position: center center;
      will-change: transform;
    }

    defs {
      rect {
        fill: #ffffff;
        position: relative;
      }
    }
  }

  .home-stage__subline {
    color: #000000;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    white-space: nowrap;
    font-family: 'DIN2014-Regular', Helvetica, sans-serif;
    font-weight: bold;
    transition: all 0.4s ease-out;
    margin-top: ${space(5)};
    ${mq('s')} {
      margin-top: ${space(8)};
    }
    ${mq('m')} {
      margin-top: ${space(11)};
    }
    ${mq('l')} {
      margin-top: ${space(13)};
    }
  }

  .home-stage__play-mobile {
    align-items: center;
    justify-content: center;
    z-index: 20;
    width: 64px;
    height: 64px;
    border: 2px solid ${color('blue', 'shade')};
    border-radius: 50%;
    margin: ${space(3)} auto 0 auto;
    color: ${color('blue', 'color')};
    display: none;

    ${mq('l')} {
      width: 84px;
      height: 84px;
    }

    svg {
      transform: translateX(2px);

      ${mq('l', 'max')} {
        width: 16px;
        height: 18px;
      }
    }

    @media (hover: none) and (pointer: coarse) {
      display: flex;
    }
  }

  &.home-stage--zoom-finished {
    .home-stage__blocker {
      ${(props: StyledHomeStageProps) =>
        !props.skipped &&
        css`
          cursor: auto;
        `}
    }

    .home-stage__skip {
      display: flex;
    }
  }

  &.home-stage--skipped {
    .home-stage__skip {
      display: none;
      pointer-events: none;
    }

    .home-stage__header {
      color: ${color('neutral', 'background')};

      svg g,
      svg path {
        fill: ${color('neutral', 'background')};
      }
    }

    .home-stage__headline-skipped {
      opacity: 1;
    }

    .home-stage__subline {
      color: ${color('neutral', 'background')};
    }

    .home-stage__play-mobile {
      border-color: ${color('neutral', 'background')};
      color: ${color('neutral', 'background')};
    }
  }

  &.home-stage--zoom-start&:not(.home-stage--skipped) {
    .home-stage__header {
      transform: translate(-50%, -30px);
      opacity: 0;
    }

    .home-stage__subline {
      transform: translate(-50%, 30px);
      opacity: 0;
    }
  }
`;

export default StyledHomeStage;
