import React from 'react';

import StyledQuote from './Quote.style';
import Typo from '../Typo';
import Richtext from '../Richtext';
import Icon from '../Icon';

export type QuoteProps = {
  alignment: 'left' | 'right' | 'center';
  author?: string;
  quote: string;
  className?: string;
};

const Quote = ({ alignment, author, quote, className }: QuoteProps): JSX.Element => (
  <StyledQuote className={className} alignment={alignment}>
    <div className="quote__inner">
      <Icon icon="quote" size={148} className="quote__icon" />
      <Typo as="cite" html={quote} type="HeadlinePrimary" />

      {author && <Richtext className="quote__source" html={author} />}
    </div>
  </StyledQuote>
);

export default Quote;
