import styled from 'styled-components';

import { color } from '../../stylesheets/tools';

const moduleName = 'cursor';

const StyledCursor = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  width: 84px;
  height: 84px;
  border: 2px solid ${color('blue', 'shade')};
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 150ms ease;
  transition-property: background-color, opacity, transform;

  &.${moduleName}--link-hovered {
    transform: translate(-50%, -50%) scale(1.25);
    background-color: #fefefe;
  }

  &.${moduleName}--clicked {
    transform: translate(-50%, -50%) scale(0.9);
    background-color: #fefefe;
  }

  &.${moduleName}--hidden {
    display: none;
  }

  .${moduleName}__icon {
    transform: translateX(2px);
  }
`;

export default StyledCursor;
