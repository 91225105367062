import React, { useRef } from 'react';
import { NextSeo } from 'next-seo';
import ScrollSnap from '../components/ScrollSnap';

import DefaultTemplate from '../templates/Default';

import HomeStage from '../components/HomeStage';
import Quote from '../components/Quote';
import ArticleTeaser from '../components/ArticleTeaser';
import HighlightCopy from '../components/HighlightCopy';

const IndexPage = (): JSX.Element => {
  const scrollSnapContainerRef = useRef<React.ElementRef<typeof ScrollSnap>>(null);

  function triggerScrollTop(scrollTop: number) {
    if (scrollSnapContainerRef?.current) {
      scrollSnapContainerRef?.current.scrollTopMy(scrollTop);
    }
  }

  return (
    <ScrollSnap ref={scrollSnapContainerRef}>
      <DefaultTemplate withHeader={false}>
        <NextSeo
          title="Initiative Milch"
          description="Die Initiative Milch stellt sich vor und redet Klartext: Über die Milch als wertvolles Lebensmittel, einzigartiges Kulturgut und ihre Bedeutung für eine optimale Ernährung."
          openGraph={{
            url: 'https://www.initiative-milch.de',
            title: 'Initiative Milch',
            description:
              'Die Initiative Milch stellt sich vor und redet Klartext: Über die Milch als wertvolles Lebensmittel, einzigartiges Kulturgut und ihre Bedeutung für eine optimale Ernährung.',
            images: [
              {
                url: 'https://www.initiative-milch.de/images/og.jpg',
                width: 1200,
                height: 630,
                alt: 'Initiative Milch'
              }
            ]
          }}
        />
        <HomeStage triggerScrollTop={triggerScrollTop} />

        <HighlightCopy
          topline="Unsere Mission"
          highlightText="<p>Wir sind die <strong>Initiative Milch -</strong> und wir wollen reden. Über die wunderbare Welt der Milch.<p>"
          copy="<p>Die <strong>Initiative Milch</strong> ist eine Plattform für den zeitgemäßen Austausch über ein wertvolles Lebensmittel und einzigartiges Kulturgut: <strong>die Milch. </strong> Wir zeigen ihre Vorzüge und ihre Bedeutung für eine ausgewogene Ernährung auf und treten in den Dialog mit den Verbraucher:innen. Dafür setzt die Initiative Milch auf eine transparente Kommunikation. Wir verstehen uns als Partner der Konsument:innen und der Milch-Branche.</p>"
        />
        <ArticleTeaser
          ctaLabel="Zum Interview"
          category="Interview"
          headline="Wir feiern die Milch!"
          description="<p>Der internationale Tag der Milch wird weltweit genutzt, um über das „weiße Wunder“ Milch zu sprechen. Hierzulande steht die noch junge Initiative Milch 2021 an der Startlinie. Drei Fragen an Geschäftsführerin Kerstin Wriedt.</p>"
          image={{
            srcSet: '/images/kerstin_wriedt/1080.jpg 1080w, /images/kerstin_wriedt/540.jpg 540w',
            alt: 'Kerstin Wriedt, Geschäftsführerin Initiative Milch',
            width: 1080,
            height: 810
          }}
        />
        <Quote
          alignment="center"
          author="<p>Dann kontaktieren Sie uns unter <a href='mailto:info@initiative-milch.de'>info@initiative-milch.de</a></p>"
          quote="Haben Sie<br>fragen?"
        />
      </DefaultTemplate>
    </ScrollSnap>
  );
};

export default IndexPage;
