import React, { ReactNode, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

import { space } from '../../stylesheets/tools';

export type ScrollSnapProps = {
  children: ReactNode;
};
export type ScrollSnapContainerType = {
  scrollTopMy: (scrollTop: number) => void;
};

const StyledScrollSnap = styled.div`
  /* scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  scroll-padding-top: ${space(3)}; */

  /* > * {
    scroll-snap-align: start;
  } */
`;

const ScrollSnap = React.forwardRef<ScrollSnapContainerType, ScrollSnapProps>(
  (props: ScrollSnapProps, ref) => {
    const scrollSnapRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, (): any => ({
      scrollTopMy: (scrollTop: number) => {
        if (scrollSnapRef?.current) {
          scrollSnapRef.current.scrollTop = scrollTop;
        }
      }
    }));

    return <StyledScrollSnap ref={scrollSnapRef}>{props.children}</StyledScrollSnap>;
  }
);

export default ScrollSnap;
