import styled from 'styled-components';

import Grid from '../Grid';
import { ArticleTeaserProps } from './ArticleTeaser';

import { mq, space, color, px2rem } from '../../stylesheets/tools';

const StyledArticleTeaser = styled(Grid)<Pick<ArticleTeaserProps, 'category'>>`
  margin-bottom: ${space(7)};
  .articleTeaser__link {
    display: grid;
    grid-template-columns: 1fr;
    grid-column: 1 / span 12;

    ${mq('m')} {
      grid-column: 3 / span 8;
    }

    ${mq('l')} {
      grid-template-columns: 1fr 1fr;
      grid-column: 2 / span 10;
    }
  }

  .articleTeaser__card {
    position: relative;
    background: ${props => color(props.theme.globalTheme, 'color')};
    color: ${color('neutral', 'shade')};
    padding: ${space(4.7)};

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    ${mq('l')} {
      margin-left: ${space(-4)};
      margin-top: ${space(10)};
      margin-bottom: ${space(10)};
    }

    &-cta {
      margin-top: ${space(2)};
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;

      svg {
        width: ${px2rem(22)};
        height: ${px2rem(14)};
        margin-left: ${space(1)};
        transition: transform 0.2s ease-out;
      }
    }
  }

  .articleTeaser__category {
    margin-bottom: ${space(1)};
  }

  .articleTeaser__description {
    margin-top: ${space(2)};
  }

  &:hover svg {
    transform: translateX(6px);
  }
`;

export default StyledArticleTeaser;
