import React from 'react';
import Link from 'next/link';

import StyledArticleTeaser from './ArticleTeaser.style';
import Image, { ImageProps } from '../Image';
import Typo from '../Typo';
import Richtext from '../Richtext';
import AspectRatio from '../AspectRatio';
import Icon from '../Icon';

export type ArticleTeaserProps = {
  category: string;
  headline: string;
  description?: string;
  ctaLabel: string;
  image?: ImageProps;
};

const ArticleTeaser = ({
  category,
  headline,
  description,
  image,
  ctaLabel
}: ArticleTeaserProps): JSX.Element => (
  <StyledArticleTeaser category={category}>
    <Link href="/interview-kerstin-wriedt">
      <a className="articleTeaser__link">
        {image && (
          <div className="articleTeaser__img">
            <AspectRatio ratio="4:3">
              <Image
                lazy
                alt={image.alt}
                srcSet={image.srcSet}
                sizes="(min-width: 1440px) 540px, (min-width: 540px) 540px, 100vw"
                className="article-stage__image"
                width={image.width}
                height={image.height}
              />
            </AspectRatio>
          </div>
        )}
        <div className="articleTeaser__card">
          <Typo as="span" type="CopyCaps" className="articleTeaser__category">
            {category}
          </Typo>
          <Typo as="h2" type="HeadlineSecondary">
            {headline}
          </Typo>
          {description && <Richtext className="articleTeaser__description" html={description} />}
          <span className="articleTeaser__card-cta">
            <Typo as="span" type="CopyCaps">
              {ctaLabel}
            </Typo>
            <Icon icon="back-small" className="articleTeaser__back-svg" inline />
          </span>
        </div>
      </a>
    </Link>
  </StyledArticleTeaser>
);

export default ArticleTeaser;
