import styled from 'styled-components';

import { space, color, mq, getGrid } from '../../stylesheets/tools';
import { QuoteProps } from './Quote';

const StyledQuote = styled.blockquote<Pick<QuoteProps, 'alignment'>>`
  margin-top: -${space(2)};
  margin-bottom: -${space(2)};
  ${getGrid()};
  position: relative;
  z-index: 0;

  ${mq('l')} {
    margin-top: -${space(4)};
    margin-bottom: -${space(4)};
  }

  .quote__inner {
    grid-column: 1 / span 12;
    text-align: center;
    padding: ${space(2)};
    margin: 0 8%;

    ${mq('m')} {
      padding: ${space(2)} ${space(4)} 0 ${space(4)};
      grid-column: 1 / span 12;
    }

    ${mq('l')} {
      margin: 0;
      grid-column: 3 / span 8;
      padding: ${space(4)} ${space(8)} 0 ${space(8)};
    }

    cite {
      display: block;
      margin: 0;
      font-style: inherit;
    }

    .quote__source {
      margin-top: ${space(3)};
    }

    cite,
    .quote__source {
      text-align: ${props => props.alignment};
    }

    .quote__icon {
      position: relative;
      z-index: -1;
      margin: auto auto ${space(-7)} auto;
      margin-right: ${props => props.alignment === 'right' && 0};
      margin-left: ${props => props.alignment === 'left' && 0};
      opacity: 0.2;

      svg {
        fill: ${color('blue', 'color')};
      }
    }
  }
`;

export default StyledQuote;
