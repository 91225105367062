import styled from 'styled-components';

import Grid from '../Grid';

import { getTypo, space, color, mq } from '../../stylesheets/tools';

const StyledHighlightCopy = styled(Grid)`
  margin-bottom: ${space(8)};

  .highlightCopy__left {
    grid-column: 1 / span 6;

    ${mq('m')} {
      grid-column: 3 / span 4;
    }
    ${mq('l')} {
      grid-column: 2 / span 4;
      margin-left: ${space(5)};
    }

    div p {
      margin-top: ${space(2)};
      color: ${color('blue', 'color')};
      ${getTypo.CopyBig};
    }
  }

  .highlightCopy__right {
    grid-column: 1 / span 12;
    margin-top: ${space(2)};

    ${mq('m')} {
      grid-column: 7 / span 4;
      margin-top: 0;
    }
    ${mq('l')} {
      grid-column: 7 / span 5;
    }
  }
`;

export default StyledHighlightCopy;
