import React from 'react';
import StyledHighlightCopy from './HighlightCopy.style';
import Typo from '../Typo';
import Richtext from '../Richtext';

export type HighlightCopyProps = {
  topline?: string;
  highlightText: string;
  copy: string;
};

const HighlightCopy = ({ topline, highlightText, copy }: HighlightCopyProps): JSX.Element => (
  <StyledHighlightCopy>
    <div className="highlightCopy__left">
      {topline && <Typo type="CopyCaps">{topline}</Typo>}
      <Richtext html={highlightText} />
    </div>

    <Richtext className="highlightCopy__right" html={copy} />
  </StyledHighlightCopy>
);

export default HighlightCopy;
